

































































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";

import BaseComponent from "@/components/base-component.vue";
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconMenu from "@/components/icons/icon-menu.vue";
import IconBack from "@/components/icons/icon-back.vue";
import IconShoppingCart from "@/components/icons/icon-shopping-cart.vue";
import { CartState } from "@/store/modules/cart";
import { SettingsState } from "@/store/modules/settings";
import { MenuLang } from "@/menu-lang";
import { ProductTypes } from "@/product-types";

@Component({
  name: "AppBarThemed",
  components: {
    BaseIcon,
    IconMenu,
    IconBack,
    IconShoppingCart,
  },
})
export default class AppBarThemed extends BaseComponent {

  private readonly cartState: CartState = getModule(CartState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  logoImageSrc: string = "";

  menuLang: any[] = MenuLang;

  productTypes: any = ProductTypes;

  showLangDropdown: boolean = false;

  logoStyles_: string = "";
  logoImgStyles_: string = "";

  get itemsCount(): number {
    const allPhotos: any[] = this.getCartItems(this.productTypes.photos_with_me);
    const singlePhotos: any[] = this.getCartItems(this.productTypes.photo);
    const frames: any[] = this.getCartItems(this.productTypes.frame);
    const photosWithFrame: any[] = this.getCartItems(this.productTypes.photosWithFrame);
    let promocodes: any[] = this.getCartItems(this.productTypes.promocode);
    promocodes = this.getGroupedByEvent(promocodes);
    return allPhotos.length + singlePhotos.length + frames.length + photosWithFrame.length + promocodes.length;
  }
  
  get theme(): any {
    return this.settingsState.theme;
  }

  get lang(): string {
    return this.settingsState.lang;
  }

  get backURL(): string {
    return this.settingsState.backURL;
  }

  get logoStyles(): string {
    return this.logoStyles_;
  }

  get logoImgStyles(): string {
    return this.logoImgStyles_;
  }

  get showBackButton(): boolean {
    return this.$store.state.buttonBack || (this.backURL && this.$route.name === 'home');
  }

  setLogoImageSrc(): void {
    if (this.theme !== "" && this.theme !== null) {
      this.logoImageSrc = this.settingsState.logo || `/img/themes/${this.theme}/img/logo.png`;
    } else if (this.theme === "") {
      this.logoImageSrc = "/img/logo-media.png";
    } else if (this.theme === null) {
      this.logoImageSrc = "";
    }
  }

  updateLogoStyles(): void {
    if (!this.smOnly) {
      this.logoStyles_ = 'left: 50%;';
      return;
    }
    
    if (this.showBackButton) {
      this.logoStyles_ = `left: 52px; width: calc(100% - 52px - 112px); max-width: 100%;`;
      return;
    }
    this.logoStyles_ = `left: 8px; width: calc(100% - 52px - 112px); max-width: 100%;`;
  }
  
  updateLogoImgStyles(): void {
    if (!this.smOnly) {
      this.logoImgStyles_ = '';
      return;
    }
    
    this.logoImgStyles_ = `height: auto; width: var(--app-bar-themed__logo-width--mobile, var(--app-bar-themed__logo-width)); max-width: calc(100% - 40px);`;
  }

  getCartItems(type: string): any[] {
    const products: any[] = Object.assign([], this.cartState.cartItems);
    if (products.length === 0) {
      return [];
    } else {      
      const found: any[] = products.filter((r: any) => r.product ? r.product.productType === type : false);
      return found;
    }
  }

  getGroupedByEvent(items: any[]): any[] {
    const grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].catalog.catalogId;
      const eventName: string = items[item].catalog.title;

      if (eventId == "" || eventName == "") continue;

      if (grouped.length == 0) {          
        let i: any = {
          eventId: eventId,
          eventName: eventName,
          items: [],
        };
        i.items.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId
        );
        if (index >= 0) {
          grouped[index].items.push(items[item]);
        } else {
          let i: any = {
            eventId: eventId,
            eventName: eventName,
            items: [],
          };
          i.items.push(items[item]);
          grouped.push(i);
        }
      }
    }
    
    return grouped;    
  }
  
  @Watch("showBackButton")
  async onBackButtonChanged(): Promise<void> {
    this.updateLogoStyles();
    this.updateLogoImgStyles();
  }

  @Watch("itemsCount")
  async onItemsCountChanged(): Promise<void> {
    this.updateLogoStyles();
    this.updateLogoImgStyles();
  }

  async onMenu(): Promise<void> {
    this.$store.dispatch('DrawerState/setShow', true);
  }

  async goBack(): Promise<void> {
    if (this.backURL && this.$route.name === 'home') {
      window.location.href = this.backURL;
      return;
    }
    this.$store.state.goBack = this.$store.state.history.pop();
  }

  async onHome(): Promise<void> {
    if (this.$route.name != 'home') {
      this.$router.push({ name: 'home' });
    }
  }

  async onCart(): Promise<void> {
    if (this.$route.name == 'cart') return;
    this.$router.push({ name: 'cart' });
  }

  async onLangClick(): Promise<void> {
    const vm = this;
    setTimeout(() => { vm.showLangDropdown = !vm.showLangDropdown; return false; }, 250);
  }

  async onLangBlur(): Promise<void> {
    const vm = this;
    setTimeout(() => { vm.showLangDropdown = false; }, 250);
  }

  async onLangSelect(lang: string): Promise<void> {
    if (!this.smOnly && !this.mdOnly) {
      this.showLangDropdown = false;    
      setTimeout(() => { this.showLangDropdown = true; }, 100);
    }
    
    await this.settingsState.setLang(lang);
  }

  setLanguagesAvailable(): void {
    const langs = this.settingsState.languages;
    if (langs) {
      const result: any[] = [];
      for (let i = 0; i < MenuLang.length; i += 1) {
        if (langs.includes(MenuLang[i].id)) {
          result.push(MenuLang[i]);
        }
      }
      this.menuLang = result;
      return;
    }
    this.menuLang = MenuLang;
  }

  mounted(): void {
    this.setLogoImageSrc();
    this.updateLogoStyles();
    this.updateLogoImgStyles();

    if (!this.smOnly && !this.mdOnly) {
      this.showLangDropdown = true;
    }

    this.setLanguagesAvailable();

    window.addEventListener("resize", () => {
        this.setLogoImageSrc();
        this.updateLogoStyles();
        this.updateLogoImgStyles();
      }
    );
  }

}
