
import MoneyFormat from "@/plugins/money-format";

export default class Common {
  
  public static getAttribute(attributes: any[], key: string): string {
    const found: any[] = attributes.filter((r: any) => r.key === key);
    if (found.length === 0) return "";
    return found[0].value;
  }

  public static formatAmount(amount: number, onlyInteger: boolean = true): string {
    if (onlyInteger) {
      amount = Math.round(amount);
    }
    const host = window.location.host;
    const isEnglishDefault = (0 === host.indexOf("us.mysport.") || 0 === host.indexOf("mysportusa."));
    const w: any = window;
    const currencyId = isEnglishDefault
      ? '840'
      : (w.MYSPORT_PHOTO_APP_SETTINGS?.currencyId || 643).toString();
    return MoneyFormat.format(amount, currencyId, onlyInteger);
  }

  public static getCurrencyCode(id: string): string {
    return MoneyFormat.getCurrency(id);
  }

}
